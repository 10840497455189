.HistoryTitle {
    grid-area: 2 / 2 / 4 / 21;;
}

.HistoryWindow {
    grid-area: 4 / 2 / 19 / 21;
    background: #eeeeee;
    border: 1px solid #666666;
    
    display: grid;
    grid-template-columns: repeat(19, 1fr);
    grid-template-rows: repeat(15, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.HistoryTable {
    grid-area: 2 / 2 / 15 / 19;
    height: 100%;
    background: white;
    border: 1px solid #4C6A91;
    border-radius: .5rem;
    overflow-x: hidden;
    overflow-y: auto;
}

.HistoryControls {
    grid-area: 19 / 2 / 21 / 21;
    background: rgb(204,204,204);
    background: linear-gradient(180deg, rgba(204,204,204,1) 0%, rgba(136,136,136,1) 100%);
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
    border-left: 1px solid #666666;
    border-right: 1px solid #666666;
    border-bottom: 1px solid #666666;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.ButtonHistoryPrevious {
    height: 75%;
    width: 7.5vw;
}
.ButtonHistoryNext {
    height: 75%;
    width: 7.5vw;
}
.InputHistorySearch {
    background: white;
}

#HistoryTable td {
    padding-top: .75vh;
    padding-bottom: .75vh;
}

table#HistoryTable {
    border-radius: .5rem;
}
#HistoryTable tr:hover {
    cursor: pointer;
    background: var(--highlight) !important;
    color: white;
}