.AdminTitle {
    grid-area: 1 / 1 / 3 / 31;
    width: 100%;
    height: 100%;
}

.AdminMenu {
    grid-area: 3 / 1 / 22 / 7;
    border-right: 2px solid #888888;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.AdminWindowContainer {
    grid-area: 3 / 7 / 22 / 31;
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: repeat(19, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}
.AdminWindow {
    background: rgb(238,238,238);
    background: linear-gradient(180deg, rgba(238,238,238,1) 0%, rgba(221,221,221,1) 100%);
    border: 1.5px solid #888888;
    border-radius: .5rem;
    box-shadow: 0px 3px 5px 0px gray;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.AdminButton {
    margin-top: 1vmin;
    height: 6vh;
    width: 90%;
    padding: 1vmin 2vmin;
    border-radius: .5rem;
    font-size: 3vh;
    font-family: 'Quicksand';
    border: .5px solid #888888;
    color: #333333;
    background: rgb(210,204,196);
    background-image: linear-gradient(180deg, rgba(239,239,239,1) 0%, rgba(60,84,116,1) 100%);
    background-size: 300% 300%;
    background-position: top;
    transition: background-position .5s, color .5s, border 1s;
}
.AdminButton:hover {
    background-position: bottom;
    color: #eee;
    border: .5px solid #7f00ff;
}
.AdminButton:focus {
    background-position: bottom;
    color: #eee;
    outline: 0;
    border-color: #7f00ff;
    box-shadow: 0 0 5px 2px #7f00ff;  
}
.AdminButton:active {
    outline: 0;
    border-color: #7f00ff;
    box-shadow: 0 0 5px 2px #7f00ff;
}
.AdminScreenTitle {
    color: white;
    background: rgb(76,106,145);
    background: linear-gradient(180deg, rgba(76,106,145,1) 0%, rgba(60,84,116,1) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5vh;
    font-family: 'Quicksand Medium'
}

/* USERS SCREEN */

.UserScreenContainer {
    grid-area: 2 / 2 / 19 / 24;
    display: grid;
    grid-template-columns: repeat(22, 1fr);
    grid-template-rows: repeat(17, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}
.UsersButtons {
    grid-area: 1 / 1 / 3 / 23;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.UsersButton {
    height: 60%;
    width: 7.5vw;
    font-size: 2vh;
}
.UsersButton:disabled {
    opacity: .75;
    pointer-events: none;
}

.UsersTable {
    grid-area: 3 / 1 / 18 / 23;
}
.UsersTable img{
    height: 1.75vh;
    filter: invert(100%) sepia(59%) saturate(2%) hue-rotate(77deg) brightness(113%) contrast(100%);
}

#UsersTable td {
    padding-top: .75vh;
    padding-bottom: .75vh;
}
#UsersTable th {
    font-size: 1.5vh;
}
table#UsersTable {
    border-radius: .5rem;
}
#UsersTable tr:hover {
    cursor: pointer;
    background: #7f00ff !important;
    color: white;
}
/* FACILITIES SCREEN */

.FacilityScreenContainer {
    grid-area: 2 / 2 / 19 / 24;
    display: grid;
    grid-template-columns: repeat(22, 1fr);
    grid-template-rows: repeat(17, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}
.FacilityScreenTitle {
    grid-area: 1 / 1 / 2 / 23;
}
.FacilityScreenWindow {
    grid-area: 2 / 1 / 18 / 23;;
}
