.AppContainer {
    grid-area: 3 / 1 / 28 / 49;
    background: #eeeeee;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(48, 1fr);
    grid-template-rows: repeat(25, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    color: #333333;
    overflow-y: hidden !important;
}

.AdminContainer {
    grid-area: 3 / 10 / 24 / 40;;
    background: rgb(221,221,221);
    background: linear-gradient(180deg, rgba(221,221,221,1) 0%, rgba(187,187,187,1) 100%);
    border: 1.5px solid #888888;
    border-radius: .5rem;
    box-shadow: 0px 3px 5px 0px gray;
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(30, 1fr);
    grid-template-rows: repeat(21, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.InputContainer {
    grid-area: 3 / 3 / 24 / 24;
    display: grid;
    grid-template-columns: repeat(21, 1fr);
    grid-template-rows: repeat(21, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background: rgb(221,221,221);
    background: linear-gradient(180deg, rgba(221,221,221,1) 0%, rgba(187,187,187,1) 100%);
    border: 1.5px solid #888888;
    border-radius: .5rem;
    box-shadow: 0px 3px 5px 0px gray;
    height: 100%;
    width: 100%;
}

.LabelContainer {
    grid-area: 3 / 26 / 24 / 47;
}
.LabelMotionWrapper {
    background: rgb(221,221,221);
    background: linear-gradient(180deg, rgba(221,221,221,1) 0%, rgba(187,187,187,1) 100%);
    border: 1.5px solid #888888;
    border-radius: .5rem;
    box-shadow: 0px 3px 5px 0px gray;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(21, 1fr);
    grid-template-rows: repeat(21, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.LoginContainer {
    grid-area: 5 / 19 / 20 / 31;
    background: rgb(221,221,221);
    background: linear-gradient(180deg, rgba(221,221,221,1) 0%, rgba(187,187,187,1) 100%);
    border: 1.5px solid #888888;
    border-radius: .5rem;
    box-shadow: 0px 3px 5px 0px gray;
    
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(15, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.HistoryContainer {
    grid-area: 3 / 3 / 24 / 24;
    background: rgb(221,221,221);
    background: linear-gradient(180deg, rgba(221,221,221,1) 0%, rgba(187,187,187,1) 100%);
    border: 1.5px solid #888888;
    border-radius: .5rem;
    box-shadow: 0px 3px 5px 0px gray;

    display: grid;
    grid-template-columns: repeat(21, 1fr);
    grid-template-rows: repeat(21, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

}
.TotalLabels {
    position: absolute;
    bottom: 0;
    left: 0;
    background: black;
    color: white;
}
.Tab {
    background: rgb(76,106,145);
    background-image: linear-gradient(0deg, rgba(76,106,145,1) 0%, rgba(187,187,187,1) 100%);
    background-size: 500% 500%;
    background-position: top;
    color: black;
    border: 1px solid black;
    font-size: 2vh;
    transition: font-size .5s, color .5s, background-position .5s, opacity .5s;
    opacity: .5;
}
.Tab:hover {
    opacity: 1;
    font-size: 2.5vh;
}
.Tab:focus {
    outline: 0;
    border-color: #7f00ff;
    box-shadow: 0 0 5px 2px #7f00ff;
    color: white;
}
.ActiveTab {
    color: white;
    background-position: bottom;
    font-size: 2.5vh;
    text-shadow: 0px 0px 3px #7f00ff;
    opacity: 1;
}

.TabViewCase {
    grid-area: 1 / 21 / 3 / 25;
    display: flex;
    align-items: center;
}
.TabViewHistory {
    grid-area: 1 / 25 / 3 / 29;
    display: flex;
    align-items: center;
}
.TabButtonCase {
    width: 100%;
    height: 66%;
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
}

.TabButtonHistory {
    width: 100%;
    height: 66%;
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
}