.App {
    height: 100vh;
    width: 100vw;
    background: white;
    display: grid;
    grid-template-columns: repeat(48, 1fr);
    grid-template-rows: repeat(27, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

svg {
    pointer-events: none;
  }