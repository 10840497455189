.AddUserContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.AddUserModal {
    width: 40vw;
    height: 75vh;
    border: 2px solid #333;
    border-radius: .5rem;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(20, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}
.AddUserTitle {
    grid-area: 1 / 1 / 3 / 21;
    background: rgb(76,106,145);
    background: linear-gradient(180deg, rgba(76,106,145,1) 0%, rgba(60,84,116,1) 100%);
    color: white;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px; 
}
.AddUserTitleText {
    grid-area: 1 / 3 / 2 / 19;
    font-size: 5vh;
}
button.AddUserButtonClose {
    all: unset;
    grid-area: 1 / 19 / 2 / 21;
    height: 5vh;
    width: 5vh;
    border-radius: 50%;
    place-self: center center;
    transition: background-color .5s;
}
button.AddUserButtonClose:hover {
    background-color: #6383ad;
}
button.AddUserButtonClose img {
    height: 50%;
    width: 50%;
    padding-left: 25%;
    padding-top: 25%;
    pointer-events: none;
    filter: invert(100%) sepia(59%) saturate(2%) hue-rotate(77deg) brightness(113%) contrast(100%);
}

.AddUserBody {
    grid-area: 3 / 1 / 19 / 21;
    background: #eee;
    color: #333;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(16, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    font-size: 2vh;
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
}
.AddUserBody img {
    margin: auto;
    width: 70%;
}

.AddUserButtonRow {
    grid-area: 19 / 1 / 21 / 21;
    background: rgb(204,204,204);
    background: linear-gradient(180deg, rgba(204,204,204,1) 0%, rgba(136,136,136,1) 100%);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.AddUserButton {
    width: 7.5vw;
    height: 75%;
}

.AddUserInput {
    height: 75%;
}
.AddUserSelect {
    height: 75%;
}

.AddUserIconUsername {
    grid-area: 2 / 3 / 5 / 6;
}
.AddUserLabelUsername {
    grid-area: 2 / 7 / 3 / 15;
}
.AddUserInputUsername {
    grid-area: 3 / 7 / 5 / 19;
}
.AddUserIconPassword {
    grid-area: 7 / 3 / 10 / 6;
}
.AddUserLabelPassword {
    grid-area: 7 / 7 / 8 / 15;
}
.AddUserInputPassword {
    grid-area: 8 / 7 / 10 / 19;
}
.AddUserIconConfirm {
    grid-area: 12 / 3 / 15 / 6;
}
.AddUserLabelConfirm {
    grid-area: 12 / 7 / 13 / 15;
}
.AddUserInputConfirm {
    grid-area: 13 / 7 / 15 / 19;
}
.AddUserIconCompany {
    grid-area: 2 / 3 / 5 / 6;
}
.AddUserLabelCompany {
    grid-area: 2 / 7 / 3 / 15;
}
.AddUserSelectCompany {
    grid-area: 3 / 7 / 5 / 19;
}
.AddUserIconLocation {
    grid-area: 7 / 3 / 10 / 6;
}
.AddUserLabelLocation {
    grid-area: 7 / 7 / 8 / 15;
}
.AddUserSelectLocation {
    grid-area: 8 / 7 / 10 / 19;
}
.AddUserIconLanguage {
    grid-area: 12 / 3 / 15 / 6;
}   
.AddUserLabelLanguage {
    grid-area: 12 / 7 / 13 / 15;
}
.AddUserSelectLanguage {
    grid-area: 13 / 7 / 15 / 19;
}

.AddUserWarningUsername {
    grid-area: 5 / 7 / 7 / 19;
    font-size: 2vh;
    color: red;
}
.AddUserWarningPassword {
    grid-area: 10 / 7 / 12 / 19;
    font-size: 2vh;
    color: red;
}

.AddUserIconFinished {
    grid-area: 3 / 7 / 11 / 15;
}
.AddUserIconFinished img {
    filter: invert(20%) sepia(92%) saturate(2739%) hue-rotate(97deg) brightness(98%) contrast(101%);
}
.AddUserMessageFinished {
    grid-area: 13 / 2 / 15 / 20;
    font-size: 3.5vh;
}